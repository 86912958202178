<template>
  <div class="AppContact">
    <div class="AppContact__inner container">
      <div class="AppContact__map" data-aos="fade-right">
        <app-map></app-map>
      </div>
      <div class="AppContact__banner">
        <ul class="AppContact__items" data-aos="fade-left">
          <li class="AppContact__item AppContact__phone">
            <a href="tel:+380503722730">
              <div class="AppContact__icon">
                <icon-phone/>
              </div>
              <div class="AppContact__phone-wrapper">
                +38 (050) 372 27 30
                <span class="AppContact__phone-text"
                >{{text1['text_' + selectedLang]}}</span
                >
              </div>
            </a
            >
          </li>
          <li class="AppContact__item">
            <a
                class="AppContact__place-point"
                target="blank"
                href="https://goo.gl/maps/f8FundFhXomE9mu3A"
            >
              <div class="AppContact__icon">
                <icon-placepoint/>
              </div>
              {{text2['text_' + selectedLang]}}</a
            >
          </li>
          <li class="AppContact__item">
            <a class="AppContact__mail" href="mailto:mrs.greenwich@gmail.com">
              <div class="AppContact__icon">
                <icon-mail/>
              </div>
              mrs.greenwich@gmail.com
            </a>
          </li>
          <li>
            <router-link to="/menu">
              <app-button class="AppContact__button button--fill"
              >{{text3['text_' + selectedLang]}}
              </app-button
              >
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import iconPhone      from '../components/svg/iconPhone.vue';
import iconPlacepoint from '../components/svg/iconPlacepoint.vue';
import iconMail       from '../components/svg/iconMail.vue';
import AppButton      from '../components/AppButton.vue';
import AppMap         from '../components/AppMap.vue';

import {Icon} from 'leaflet';
import {mapGetters} from "vuex";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconUrl: require('@/assets/images/map/placeholder.svg'),
});

export default {
  name: 'AppContact',
  components: {iconPhone, iconPlacepoint, iconMail, AppButton, AppMap},
  data() {
    return {
      text1: {
        text_ru: 'Бесплатно по Украине с любого номера',
        text_ua: 'Безкоштовно по Україні з будь-якого номера',
        text_pl: 'Uwolnić wewnątrz Ukrainy z dowolnej liczby',
        text_en: 'Free for Ukraine from any number',
      },
      text2: {
        text_ru: 'Львов, ул. Пекарская, 24',
        text_ua: 'Львів, вул. Пекарська, 24',
        text_pl: 'Lwów, ul. Pekarska, 24',
        text_en: 'Lviv, st. Pekarskaya, 24',
      },
      text3: {
        text_ru: 'Заказать прямо домой',
        text_ua: 'Замовити прямо додому',
        text_pl: 'Zamów bezpośrednio do domu',
        text_en: 'Order directly home',
      }
    };
  },
  computed: {
    ...mapGetters('lang', ['selectedLang'])
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.myMap?.mapObject.ANY_LEAFLET_MAP_METHOD();
    });
  },
};
</script>

<style lang="scss">
.AppContact {
  position: relative;

  @include media-d-m($screen-netbook) {
    margin-top: 0px;
  }

  &:after {
    content: '';
    position: absolute;
    @include position();
    box-shadow: inset 0 -85px 85px -15px $body-color;
    z-index: 2;
  }

  &__inner {
    position: relative;
    @include flex(flex-start, center, row, nowrap);

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, center, column, nowrap);
    }
  }

  &__map {
    z-index: 3;
    position: relative;
    width: calc(50% + 200px);
    height: 900px;

    margin-left: -200px;

    @include media-d-m($screen-desktop-large) {
      width: calc(50% + 300px);
      margin-left: -300px;
    }

    @include media-d-m($screen-netbook) {
      width: calc(100% + 6%);
      margin-left: 0;
      height: 300px;

      margin-bottom: 40px;
    }
  }

  &__map-inner {
    min-height: 900px;
    width: 100%;
  }

  &__banner {
    width: 50%;
    height: 100%;
    @include flex(center, center, column, nowrap);
    z-index: 3;

    @include media-d-m($screen-netbook) {
      width: 100%;
    }
  }

  &__item {
    @include flex(flex-start, center, row, nowrap);

    &:not(:last-of-type) {
      margin-bottom: 50px;

      @include media-d-m($screen-netbook) {
        margin-bottom: 30px;
      }
    }

    & a {
      @include flex(flex-start, center, row, nowrap);
    }
  }

  &__icon {
    min-width: 40px;
    margin-right: 15px;
  }

  &__phone-wrapper {
    @include flex(center, flex-start, column, nowrap);
    @include text($h20, 300, $light-yellow, $f-montserrat);
  }

  &__phone-text {
    @include text($h10, 300, $grey, $f-montserrat);
  }

  &__place-point {
    @include text($h15, 400, $light-yellow, $f-montserrat);
  }

  &__mail {
    @include text($h16, 300, $light-yellow, $f-montserrat);
  }

  &__button {
    margin-top: 96px;

    @include media-d-m($screen-netbook) {
      margin-top: 54px;
    }
  }
}
</style>
